import React from 'react';
import _ from 'lodash';

export default class Toogle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        }
        this.clickHandler = this.clickHandler.bind(this);
    }
    clickHandler() {
        this.setState({
            collapse: !this.state.collapse
        });
        if (this.props.clickCallback) {
            this.props.clickCallback();
        }
    }
    render() {
        return (
            <div className={`plus-minus-toggle ${this.state.collapse ? 'collapsed' : ''}`} onClick={this.clickHandler}></div>
        );
    }
}
