import React from 'react';
import _ from 'lodash';
import anime from 'animejs';

import { Layout } from '../components/index';
import Toggle from '../components/Toggle';
import Product from '../components/Product';
import { safePrefix, htmlToReact, Link } from '../utils';

import Funnel from '../svg/funnel.svg';
const productList = require('../data/productList.json');

export default class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.typeSection = React.createRef();
        this.colourSection = React.createRef();
        this.productBlocks = React.createRef();
        this.toggleSection = this.toggleSection.bind(this);
        this.toogleFilter = this.toogleFilter.bind(this);
        this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
        this.closeFilterPanel = this.closeFilterPanel.bind(this);
        this.updateFilterArr = this.updateFilterArr.bind(this);

        this.locale = _.get(this.props, 'pageContext.locale');
        this.state = {
            typeFilter: [],
            colourFilter: [],
            openMobileFilter: false
        };
        this.FILTER_TYPE = { PRODUCT_TYPE: 0, COLOUR: 1 };
    }

    componentDidMount() {
        this.checkAllFilter();
        this.animation = anime({
            easing: 'easeInOutExpo',
            targets: this.productBlocks.current,
            translateY: ['50px', '0px'],
            opacity: [0, 1],
        })
    }

    updateFilterArr(target, item) {
        const newArr = this.state[target];
        newArr.includes(item) ? _.remove(newArr, n => n === item) : newArr.push(item);
        this.setState({ [target]: newArr });
        if (this.animation) {
            this.animation.play();
        }
    }

    toggleSection(section) {
        section.current.classList.toggle('active');
    }

    toogleFilter(filterType, item) {
        switch (filterType) {
            case (this.FILTER_TYPE.PRODUCT_TYPE):
                this.updateFilterArr('typeFilter', item);
                break;
            case (this.FILTER_TYPE.COLOUR):
                this.updateFilterArr('colourFilter', item);
                break;
        }
    }

    checkAllFilter() {
        var inputs = document.getElementsByTagName("input");
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].type == "checkbox") {
                inputs[i].click();
            }
        }
    }

    uniqueArr(arr) {
        return _.uniq([].concat.apply([], arr));
    }

    toggleFilterPanel() {
        this.setState({
            openMobileFilter: !this.state.openMobileFilter
        })
    }

    closeFilterPanel() {
        this.setState({
            openMobileFilter: false
        })
    }

    render() {
        const filteredProducts = productList.products.filter(product =>
            _.intersection(product.colours.map(colour => colour[this.locale]), this.state.colourFilter).length > 0 &&
            _.intersection(product.types.map(type => type[this.locale]), this.state.typeFilter).length > 0
        );
        return (
            <Layout {...this.props}>
                <div className="product-list-outer-container">
                    <div className="product-list-controls">
                        <div className="product-list-control-btn-wrapper">
                            <button className="product-control-button" onClick={this.toggleFilterPanel}>
                                <img src={Funnel} />
                                {_.get(this.props, 'pageContext.frontmatter.filter')}
                            </button>
                        </div>
                    </div>
                    <div className="product-list-container">
                        <div className={`product-list-content-backdrop ${this.state.openMobileFilter ? 'open' : ''}`} onClick={this.closeFilterPanel} />
                        <div className={`product-list-content ${this.state.openMobileFilter ? 'open' : ''}`}>
                            <div className="product-list-left-container">
                                <div className="product-list-product-count">
                                    {filteredProducts.length} {_.get(this.props, 'pageContext.frontmatter.count')}
                                </div>
                                <div className="product-list-filter">
                                    <div className="product-list-filter-header">
                                        <div className="product-list-filter-title">{_.get(this.props, 'pageContext.frontmatter.type')}</div>
                                        <div className="product-list-filter-symbol">
                                            <Toggle clickCallback={() => this.toggleSection(this.typeSection)} />
                                        </div>
                                    </div>
                                    <div className="product-list-filter-body active" ref={this.typeSection}>
                                        {
                                            this.uniqueArr(this.uniqueArr(productList.products
                                                .map((product) => product.types)
                                                .map(type => type))
                                                .map(type => type[this.locale]
                                                ))
                                                .map(type =>
                                                    <div className="product-list-filter-item">
                                                        <div className="product-list-filter-title">
                                                            {type}
                                                        </div>
                                                        <div className="product-list-filter-symbol">
                                                            <input type="checkbox" className="checkbox" onClick={() => this.toogleFilter(this.FILTER_TYPE.PRODUCT_TYPE, type)} />
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                <div className="product-list-filter">
                                    <div className="product-list-filter-header">
                                        <div className="product-list-filter-title">{_.get(this.props, 'pageContext.frontmatter.colour')}</div>
                                        <div className="product-list-filter-symbol">
                                            <Toggle clickCallback={() => this.toggleSection(this.colourSection)} />
                                        </div>
                                    </div>
                                    <div className="product-list-filter-body active" ref={this.colourSection}>
                                        {
                                            this.uniqueArr(this.uniqueArr(productList.products
                                                .map((product) => product.colours)
                                                .map(colour => colour))
                                                .map(colour => colour[this.locale]
                                                ))
                                                .map(colour =>
                                                    <div className="product-list-filter-item">
                                                        <div className="product-list-filter-title">
                                                            {colour}
                                                        </div>
                                                        <div className="product-list-filter-symbol">
                                                            <input type="checkbox" className="checkbox" onClick={() => this.toogleFilter(this.FILTER_TYPE.COLOUR, colour)} />
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-list-right-container" ref={this.productBlocks}>
                            {
                                filteredProducts.length > 0 ?
                                    filteredProducts.map((product, index) => <Product key={index} locale={_.get(this.props, 'pageContext.locale')} {...product} />) :
                                    <div className="no-product-msg-wrapper">{_.get(this.props, 'pageContext.frontmatter.noData')}</div>
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
